.aadhar-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .box {
    width: 598px;
    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
      0px 10px 32px -4px rgba(24, 39, 75, 0.1);
    border-radius: 15px;
    height: 470px;
    padding: 40px 23px 0 23px;

    .container {
      padding: 0 102px;
      p {
        margin-top: 40px;
        @extend .text1;
        margin-bottom: 100px;
      }
      h1 {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 5px;
        letter-spacing: -0.02em;
        color: #6b7588;
        margin-top: 23px;
      }
      .cta-container {
        margin-top: 31px;
      }
    }
  }
}

.aadhar-upload-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .box {
    width: 598px;
    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
      0px 10px 32px -4px rgba(24, 39, 75, 0.1);
    border-radius: 15px;
    height: 470px;
    padding: 40px 23px 0 23px;

    .container {
      padding: 0 102px;
      p {
        margin-top: 40px;
        @extend .text1;
        span {
          color: darkgray;
        }
      }

      .upload-photo-container {
        margin-top: 40px;
      }
      .cta-container {
        margin-top: 40px;
      }
    }
  }
}

@media screen and (max-width: $desktop-breakpoint) {
  .aadhar-screen {
    .box {
      border-radius: 0px;
      width: 100%;
      box-shadow: none;
      height: auto;
      padding: 0px 5px 40px 5px;

      .container {
        padding: 0 10px;
      }
    }
  }

  .aadhar-upload-screen {
    .box {
      border-radius: 0px;
      width: 100%;
      box-shadow: none;
      height: auto;
      padding: 0px 5px 40px 5px;

      .container {
        padding: 0 20px;
      }
    }
  }
}
