.primary-cta {
  .MuiButton-root {
    width: 300px;
    height: 60px;
    background: #021751;
    border-radius: 15px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #ffffff;
    text-transform: capitalize;
  }
}

.cta-container {
  display: flex;
  justify-content: center;
}
