.upload-photo-container {
  .upload-photo {
    height: 172px;
    border: 1px dashed #c4c4c4;
    box-sizing: border-box;
    border-radius: 15px;
    text-align: center;
    justify-content: center;

    img {
      margin-top: 50px;
    }
    .MuiButton-root {
      width: 172px;
      height: 40px;
      margin-top: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .upload-photo-loader {
    height: 172px;
    border: 1px dashed #c4c4c4;
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload-photo-img {
    height: 172px;
    border: 1px dashed #c4c4c4;
    box-sizing: border-box;
    border-radius: 15px;
    img {
      height: inherit;
      width: 100%;
      object-fit: contain;
    }
  }
}
