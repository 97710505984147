@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.text1 {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #303030;
}

$desktop-breakpoint: 600px;
