.home-screen {
  display: flex;
  flex-direction: column;
  align-items: center;

  .box {
    width: 598px;
    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
      0px 10px 32px -4px rgba(24, 39, 75, 0.1);
    border-radius: 15px;
    height: 470px;
    padding-top: 80px;
    padding-left: 133px;
    padding-right: 133px;
    p {
      margin-top: 7px;
      @extend .text1;
    }
    .checkbox-bar {
      margin-top: 57px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
      p {
        @extend .text1;
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}

@media screen and (max-width: $desktop-breakpoint) {
  .home-screen {
    .box {
      border-radius: 0px;
      width: 100%;
      box-shadow: none;
      height: auto;
      padding: 0px 10px 40px 10px;
    }
  }
}

.home-loading-screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
